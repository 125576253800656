import React from "react";
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import BasicFormParticipantRegister from "./components/basicFormParticipantRegister";

const AddParticipant = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024" />
      <Layout isSticky>
        <BasicFormParticipantRegister />
      </Layout>
    </div>
  );
};

export default AddParticipant;
